import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import AudioPlayer from "../pages/components/audioplayer";
import "../css/index.css";
import OldMap from "../images/sketches/oldmap.svg";
import MerchantShip from "../images/sketches/merchant-ship-white.svg";
import Compass from "../images/sketches/compass.svg";
import Coins from "../images/sketches/coins-white.svg";
import HandQuill from "../images/sketches/handquill.svg";
import Book from "../images/sketches/book.svg";
import Next from "../images/logos/next-button.svg";
import Previous from "../images/logos/previous-button.svg";
import Slide from "react-reveal/Slide";
import TestAudio from "../audio/WATERFORD Stop14.mp3";

function List(props) {
  return (
    <Slide left>
      <li className="list-arrow py-4 pl-2 text-white">
        <a href={props.link}>{props.name}</a>
      </li>
    </Slide>
  );
}

export default function ChamberCommerce() {
  const [isActive, setIsActive] = useState(false);

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  const cards = [
    {
      id: 19,
      cardName: "Silver Museum",
      text: "https://www.waterfordtreasures.com/irish-silver-museum",
      active: true,
    },
  ];

  return (
    <Layout pageTitle="The Port of Waterford Building">
    <div className="lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - The Port of Waterford Building</title>
      <meta name="description" content="The Port of Waterford Building. A location on digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - The Port of Waterford Building" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/chambercommerce" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

      <div className="w-full bg-waterford-ocean h-24"></div>
      <section className="chambercommerce-bg w-full">
        <div className="w-10/12">
          <h1 className="font-lower p-6 text-white">
            <span className="font-heading text-xl">
              <Trans>ChamberCommerce</Trans>
            </span>
            <br />
            <span className="font-bold text-4xl"><Trans>ChamberCommerceSubtitle</Trans></span>
          </h1>
        </div>
        <div className="pt-64 text-center">
          <div className="text-center">
            <AudioPlayer url={TestAudio} transcript={'/transcripts/carewtranscript'}/>
          </div>
        </div>
      </section>

      <div>
        <div className="accordion pt-16 bg-waterford-ocean"></div>
      </div>

      <button className="p-6 w-full bg-waterford-ocean ring ring-waterford-cyan-30 text-waterford-orange text-left">
        <div
          className="accordion-title grid grid-cols-6 py-6"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="col-span-5 font-lower text-2xl">
            <Trans>LearnMore</Trans>
          </div>
        </div>
        {isActive && (
          <>
            <ul className="accordion-content list-outside font-heading text-xl pl-12">
              {cards.map((card) => (
                <List key={card.id} name={card.cardName} link={card.text} />
              ))}
            </ul>
          </>
        )}
      </button>

      <section className="location-hero py-16">
        <div class="tab-content" id="tabs-tabContentJustify">
          <div
            class="tab-pane fade show active"
            id="tabs-homeJustify"
            role="tabpanel"
            aria-labelledby="tabs-home-tabJustify"
          >
            <div className="w-7/12">
              <h1 className="font-lower text-white p-6">
                <br />
                <span className="font-bold text-4xl"><Trans>DidYouKnow</Trans></span>
              </h1>
            </div>
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-6 lg:mx-auto">1</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>CarewFactOne</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  <Trans>CarewFactContentOne</Trans>
                </p>
              </div>
              <div className="py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img className="w-full col-span-2" src={Coins} alt="Coins" />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>CarewFactTwo</Trans>
                </p>
                <h3 className="text-8xl lg:mx-auto">2</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white text-sm col-span-2">
                <Trans>CarewFactContentTwo</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide up>
                  <div></div>
                  <img className="w-full col-span-2" src={Book} alt="Book" />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-cyan w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-4 lg:mx-auto">3</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>CarewFactThree</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  <Trans>CarewFactContentThree</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide up>
                  <div></div>
                  <img
                    className="w-full col-span-2"
                    src={Compass}
                    alt="Compass"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-blue w-6/6 py-6">
              <div className="w-7/12 pt-16">
                <h1 className="font-lower text-white p-6">
                  <span className="font-heading text-xl">
                    <Trans>ChamberCommerce</Trans>
                  </span>
                  <br />
                  <span className="font-bold text-4xl"><Trans>Timeline</Trans></span>
                </h1>
              </div>
              <div className="text-waterford-red w-6/6 py-6">
                <div className="py-6 ">
                  <Slide left>
                  <img className="w-full" src={OldMap} alt="Old Map" />
                  </Slide>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower text-right px-6">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1683</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-right px-6">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    <Trans>CarewTimelineOne</Trans>
                  </p>
                </div>
              </div>

              <div className="bg-cyan-circle text-waterford-ocean w-6/6 py-16">
                <div className="py-6 grid grid-cols-3">
                  <Slide left>
                    <img
                      className="w-full col-span-2 pb-12"
                      src={MerchantShip}
                      alt="Merchant Ship"
                    />
                    <div className="w-full"></div>
                  </Slide>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6">
                  <h3 className="text-6xl lg:mx-auto">1713</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading pb-12 w-7/12">
                  <p className=" text-sm">
                    <Trans>CarewTimelineTwo</Trans>
                  </p>
                  <div></div>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1731</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    <Trans>CarewTimelineThree</Trans>
                  </p>
                </div>
                <div className="py-6 lg:mx-auto font-heading gap-sm">
                  <Slide bottom>
                  <img className="w-full px-2 mx-auto w-3/6" src={HandQuill} alt="HandQuill" />
                  </Slide>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="py-6 lg:mx-auto  gap-2">
                  <div className="px-6">
                    <h3 className="text-6xl lg:mx-auto font-lower pb-4">
                      1750s
                    </h3>
                    <p className="text-white text-sm font-heading">
                    <Trans>CarewTimelineFour</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <Link to="/granville">
              <img className="w-2/6 mx-auto" src={Previous} alt="Previous" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>PreviousStop</Trans>
            </p>
          </div>
          <div>
            <Link to="/beachtower">
              <img className="w-2/6 mx-auto" src={Next} alt="Next" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>NextStop</Trans>
            </p>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;